import {
  ApiResponse,
  IKhoResponseBase, PaginationResponseBase,
} from '@/core/interfaces/ApiResponses';
import { apiServiceInstance } from '@/core/helpers/utils';

export interface IAssignVatTuItemsKhoRequest {
  id_kho: number;
  vat_tu_items: {
    id: number;
    so_luong: number;
  }[]
}

export interface IKhoCreateRequest {
  ten: string,
  ma_kho: string;
  dia_chi: string,
  ghi_chu?: string,
  id_tai_khoan: number,
  ma_tai_khoan: string;
  ma_chi_tiet: string;
  ten_tai_khoan: string;
}

export interface IKhoUpdateRequest extends IKhoCreateRequest {
  id: number;
}

export class KhoService {
  static fetchAllWarehouses(): Promise<{
    data: ApiResponse<PaginationResponseBase<IKhoResponseBase>>
  }> {
    return apiServiceInstance().vueInstance.axios.get('/api/v1/kho/danh-sach');
  }

  static get(id: number): Promise<{
    data: ApiResponse<IKhoResponseBase>;
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/kho/chi-tiet/${id}`
    );
  }

  static fetchAllWarehousesByDepartmentId(departmentId: string): Promise<{
    data: ApiResponse<PaginationResponseBase<IKhoResponseBase>>
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/kho/danh-sach/by-don-vi-id?id_don_vi=${departmentId}`
    );
  }

  static createNewWarehouse({
    ten,
    ma_kho,
    dia_chi,
    ghi_chu,
    id_tai_khoan,
    ma_chi_tiet,
    ma_tai_khoan,
    ten_tai_khoan,
  }: IKhoCreateRequest): Promise<{
    data: ApiResponse<IKhoResponseBase>;
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      'Content-Type'
    ] = 'multipart/form-data';
    const bodyFormData = new FormData();
    bodyFormData.append('kho[ma_kho]', ma_kho);
    bodyFormData.append('kho[ten_kho]', ten);
    bodyFormData.append('kho[ghi_chu]', ghi_chu ? ghi_chu : '');
    bodyFormData.append('kho[dia_chi]', dia_chi);
    bodyFormData.append('kho[tk_ketoan][id]', id_tai_khoan.toString());
    bodyFormData.append('kho[tk_ketoan][matk]', ma_tai_khoan);
    bodyFormData.append('kho[tk_ketoan][mact]', ma_chi_tiet);
    bodyFormData.append('kho[tk_ketoan][tentk]', ten_tai_khoan);

    return apiServiceInstance().vueInstance.axios.post(
      '/api/v1/kho/tao-moi',
      bodyFormData
    );
  }

  static fetchWarehouseDetailById(id: string): Promise<{
    data: ApiResponse<IKhoResponseBase>;
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/kho/chi-tiet/${id}`
    );
  }

  static updateWarehouse({
    id,
    ten,
    dia_chi,
    ma_kho,
    ghi_chu,
    id_tai_khoan,
    ma_chi_tiet,
    ma_tai_khoan,
    ten_tai_khoan,
  }: IKhoUpdateRequest) {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      'Content-Type'
    ] = 'multipart/form-data';
    const bodyFormData = new FormData();
    bodyFormData.append('kho[ten_kho]', ten);
    bodyFormData.append('kho[ma_kho]', ma_kho);
    bodyFormData.append('kho[ghi_chu]', ghi_chu ? ghi_chu : '');
    bodyFormData.append('kho[dia_chi]', dia_chi);

    bodyFormData.append('kho[tk_ketoan][id]', id_tai_khoan.toString());
    bodyFormData.append('kho[tk_ketoan][matk]', ma_tai_khoan);
    bodyFormData.append('kho[tk_ketoan][mact]', ma_chi_tiet);
    bodyFormData.append('kho[tk_ketoan][tentk]', ten_tai_khoan);

    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/kho/cap-nhat/${id}`,
      bodyFormData
    );
  }

  static assignSupply(warehouseId: number, supplyId: number, quantity: number) {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      'Content-Type'
    ] = 'multipart/form-data';
    const bodyFormData = new FormData();
    bodyFormData.append(
      `vatTuInstance[${supplyId}][so_luong]`,
      quantity.toString()
    );
    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/kho/assign/${warehouseId}`,
      bodyFormData
    );
  }

  static assignVatTuItemVaoKho({id_kho, vat_tu_items}: IAssignVatTuItemsKhoRequest) {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      'Content-Type'
    ] = 'multipart/form-data';
    const bodyFormData = new FormData();

    vat_tu_items.forEach(({ id, so_luong }) => {
      bodyFormData.append(
        `khoVatTu[${id}][so_luong]`,
        so_luong.toString()
      );
    });

    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/kho/kho-vat-tu/${id_kho}`,
      bodyFormData
    );
  }

  static removeVatTuKho({id_kho, id_vat_tu}: {id_kho: number, id_vat_tu: number}) {
    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/kho/kho-vat-tu/xoa/${id_kho}/${id_vat_tu}`,
    );
  }

  static unAssignSupply(id: number) {
    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/kho/unassign/${id}`
    );
  }

  static searchKhoItems(term: string): Promise<{
    data: {
      data: IKhoResponseBase[],
    }
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/kho/tim-kiem?s=${term}`
    );
  }

  static delete(id) {
    return apiServiceInstance().vueInstance.axios.post(`/api/v1/kho/xoa/${id}`);
  }
}
