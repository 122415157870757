import {
  ApiResponse,
  MessageItemResponse,
  IVatTuResponseBase, PaginationResponseBase,
  IVatTuTrongKhoResponseBase, IVatTuSearchInKhoResponseBase,
} from '@/core/interfaces/ApiResponses';
import { apiServiceInstance } from "@/core/helpers/utils";
import { IDanhSachVatTuFilterParams } from '../interfaces/ApiRequest.interface';

export class VatTuService {
  static list({
    current_page,
    id_dm_vat_tu,
    s,
    per_page,
    vat_tu_ids,
    id_kho,
  }: IDanhSachVatTuFilterParams): Promise<{
    data: ApiResponse<PaginationResponseBase<IVatTuResponseBase>>
  }> {
    let paramVatuTuArr = '';

    if (vat_tu_ids && vat_tu_ids.length) {
      paramVatuTuArr = vat_tu_ids.map(id => `id_vat_tu[]=${id}`).join('&');
    }

    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/vat-tu/danh-sach?${paramVatuTuArr}`,
      {
        params: {
          page: current_page || 1,
          ...(s && { s }),
          ...(id_kho && { id_kho }),
          ...(id_dm_vat_tu && { id_dm_vat_tu }),
        },
        headers: {
          perpage: per_page || 50,
        }
      }
    );
  }

  static getVatTuItemsByKhoId({ idKho, page, perPage, searchTerm }: {
    idKho: number,
    page: number,
    perPage: number,
    searchTerm?: string,
  }): Promise<{
    data: ApiResponse<PaginationResponseBase<IVatTuTrongKhoResponseBase>>
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/kho/chi-tiet/${idKho}/vat-tu`, {
        params: {
          page,
          ...(searchTerm && {
            s: searchTerm,
          }),
        },
        headers: {
          perpage: perPage,
        }
      }
    );
  }

  static create(
    name: string,
    unitString: string,
    minimumReserve: number,
    maximumReserve: number,
    recall: boolean,
    isApprovedByComp: boolean,
    accountId = 0,
    maDanhMucVatTu: number,
    thongTinTaiKhoan: {
      ma_tai_khoan: number,
      ma_chi_tiet: string,
      ten_tk: string,
    },
    soDuDauNam = 0,
  ): Promise<{
    data: ApiResponse<IVatTuResponseBase | MessageItemResponse>;
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
    ] = "multipart/form-data";

    const bodyFormData = new FormData();

    bodyFormData.append("vatTu[ten_vat_tu]", name);

    if (unitString) {
      bodyFormData.append("vatTu[don_vi_tinh]", unitString);
    }

    bodyFormData.append(
      "vatTu[muc_du_tru_toi_thieu]",
      minimumReserve.toString()
    );

    bodyFormData.append(
      "vatTu[muc_du_tru_toi_da]",
      maximumReserve.toString()
    );

    bodyFormData.append("vatTu[thu_hoi]", recall ? "1" : "0");

    bodyFormData.append(
      "vatTu[cong_ty_duyet]",
      isApprovedByComp ? "1" : "0"
    );

    bodyFormData.append("vatTu[id_tai_khoan]", accountId.toString());

    if (maDanhMucVatTu > 0) {
      bodyFormData.append("vatTu[id_danh_muc][]", maDanhMucVatTu.toString());
    }

    if (thongTinTaiKhoan.ma_chi_tiet) {
      bodyFormData.append(
        'vatTu[ma_ct]',
        thongTinTaiKhoan.ma_chi_tiet,
      );
    }

    if (thongTinTaiKhoan.ma_tai_khoan) {
      bodyFormData.append(
        'vatTu[ma_tk]',
        thongTinTaiKhoan.ma_tai_khoan.toString()
      );
    }

    if (thongTinTaiKhoan.ten_tk) {
      bodyFormData.append(
        'vatTu[ten_tk]',
        thongTinTaiKhoan.ten_tk.toString()
      );
    }

    if (soDuDauNam) {
      bodyFormData.append(
        'vatTu[so_du_dau_nam]',
        soDuDauNam.toString()
      );
    }

    return apiServiceInstance().vueInstance.axios.post(
      "/api/v1/vat-tu/tao-moi",
      bodyFormData
    );
  }

  static fetchSupplyDetail(id: number): Promise<{
    data: ApiResponse<IVatTuResponseBase>;
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/vat-tu/chi-tiet/${id}`
    );
  }

  static updateSupply(
    id: number,
    name: string,
    unitString: string,
    minimumReserve: number,
    maximumReserve: number,
    recall: boolean,
    isApprovedByComp: boolean,
    accountId = 0,
    maDanhMucVatTu: number,
    code: string,
    thongTinTaiKhoan: {
      ma_tai_khoan: number,
      ma_chi_tiet: string,
      ten_tk: string,
    },
    soDuDauNam = 0,
  ): Promise<{
    data: ApiResponse<IVatTuResponseBase>;
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
    ] = "multipart/form-data";

    const bodyFormData = new FormData();

    bodyFormData.append("vatTu[ten_vat_tu]", name);

    if (code) {
      bodyFormData.append("vatTu[ma_vat_tu]", code);
    }

    if (unitString) {
      bodyFormData.append("vatTu[don_vi_tinh]", unitString);
    }

    bodyFormData.append(
      "vatTu[muc_du_tru_toi_thieu]",
      minimumReserve.toString()
    );

    bodyFormData.append(
      "vatTu[muc_du_tru_toi_da]",
      maximumReserve.toString()
    );

    bodyFormData.append("vatTu[thu_hoi]", recall ? "1" : "0");

    bodyFormData.append(
      "vatTu[cong_ty_duyet]",
      isApprovedByComp ? "1" : "0"
    );

    bodyFormData.append("vatTu[id_tai_khoan]", accountId.toString());

    if (maDanhMucVatTu > 0) {
      bodyFormData.append("vatTu[id_danh_muc][]", maDanhMucVatTu.toString());
    }

    if (thongTinTaiKhoan.ma_chi_tiet) {
      bodyFormData.append(
        'vatTu[ma_ct]',
        thongTinTaiKhoan.ma_chi_tiet,
      );
    }

    if (thongTinTaiKhoan.ma_tai_khoan) {
      bodyFormData.append(
        'vatTu[ma_tk]',
        thongTinTaiKhoan.ma_tai_khoan.toString()
      );
    }

    if (thongTinTaiKhoan.ten_tk) {
      bodyFormData.append(
        'vatTu[ten_tk]',
        thongTinTaiKhoan.ten_tk.toString()
      );
    }

    if (soDuDauNam) {
      bodyFormData.append(
        'vatTu[so_du_dau_nam]',
        soDuDauNam.toString()
      );
    }
    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/vat-tu/cap-nhat/${id}`,
      bodyFormData
    );
  }

  static search(
    term: string,
    categoryId = 0
  ): Promise<{
    data: {
      data: IVatTuResponseBase[];
    };
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/vat-tu/tim-kiem?s=${term}${
        categoryId && categoryId > 0 ? `&cid=${categoryId}` : ""
      }`
    );
  }

  static searchByMaVatTu(term: string): Promise<{
    data: ApiResponse<PaginationResponseBase<IVatTuResponseBase>>
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/vat-tu/tim-kiem/ma_vat_tu`, {
        params: {
          s: term,
        }
      }
    );
  }

  static searchByMaVatTuV2(term: string): Promise<{
    data: ApiResponse<PaginationResponseBase<IVatTuResponseBase>>
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/vat-tu/tim-kiem`, {
        params: {
          s: term,
        }
      }
    );
  }

  static getSearch({
    term, id_kho, id_kho_exclude, id_dm_vat_tu
  }: {
    term: string,
    id_kho?: number,
    id_kho_exclude?: number,
    id_dm_vat_tu?: number
  }): Promise<{
    data: ApiResponse<IVatTuResponseBase[] | IVatTuSearchInKhoResponseBase[]>
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/vat-tu/tim-kiem`, {
        params: {
          s: term,
          ...(id_kho && {
            id_kho
          }),
          ...(id_kho_exclude && {
            id_kho_exclude
          }),
          ...(id_dm_vat_tu && {
            id_dm_vat_tu,
          }),
        }
      }
    );
  }

  static getVatTuInKho({
    idKho,
    vat_tu_ids,
    perPage,
  }: {
    idKho: number;
    vat_tu_ids?: number[];
    perPage?: number;
  }): Promise<{
    data: ApiResponse<PaginationResponseBase<IVatTuTrongKhoResponseBase>>
  }> {
    let paramVatuTuArr = '';

    if (vat_tu_ids && vat_tu_ids.length) {
      paramVatuTuArr = vat_tu_ids.map(id => `id_vat_tu[]=${id}`).join('&');
    }

    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/kho/chi-tiet/${idKho}/vat-tu?${paramVatuTuArr}`,
      {
        headers: {
          perpage: perPage || 1000,
        }
      }
    );
  }

  static delete(id: number) {
    return apiServiceInstance().vueInstance.axios.post(`/api/v1/vat-tu/xoa/${id}`);
  }
}
