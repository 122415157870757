import {
  ApiResponse, IDanhMucVatTuResponseBase, PaginationResponseBase,
} from '@/core/interfaces/ApiResponses';
import { apiServiceInstance } from "@/core/helpers/utils";

export interface ICreateDanhMucVatTuPayload {
  ten_danh_muc: string,
  ma_danh_muc: string,
  mo_ta: string,
  id_cha: number;
  id_tai_khoan: number,
  ma_tai_khoan: string;
  ma_chi_tiet?: string;
  ten_tai_khoan: string;
}

export interface IUpdateDanhMucVatTuPayload extends ICreateDanhMucVatTuPayload {
  id: number;
}

export default class DanhMucVatTuService {
  static get(id: number): Promise<{
    data: ApiResponse<IDanhMucVatTuResponseBase>
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/danh-muc-vat-tu/chi-tiet/${id}`
    );
  }

  static list(
    current_page = 1,
    per_page = 200,
  ): Promise<{
    data: ApiResponse<PaginationResponseBase<IDanhMucVatTuResponseBase>>
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      '/api/v1/danh-muc-vat-tu/danh-sach', {
        params: {
          page: current_page,
        },
        headers: {
          perpage: per_page,
        }
      }
    )
  }

  static create({
    ten_danh_muc,
    ma_danh_muc,
    mo_ta,
    id_cha,
    id_tai_khoan,
    ma_chi_tiet,
    ma_tai_khoan,
    ten_tai_khoan
  }: ICreateDanhMucVatTuPayload): Promise<{
    data: ApiResponse<IDanhMucVatTuResponseBase>
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
      ] = "multipart/form-data";
    const bodyFormData = new FormData();

    bodyFormData.append('danhMucVatTu[ten_danh_muc]', ten_danh_muc);
    bodyFormData.append('danhMucVatTu[ma_danh_muc]', ma_danh_muc);
    bodyFormData.append('danhMucVatTu[mo_ta]', mo_ta);
    bodyFormData.append('danhMucVatTu[id_cha]', id_cha.toString());

    bodyFormData.append('danhMucVatTu[tk_ketoan][id]', id_tai_khoan.toString());
    bodyFormData.append('danhMucVatTu[tk_ketoan][matk]', ma_tai_khoan);

    bodyFormData.append('danhMucVatTu[tk_ketoan][mact]', ma_chi_tiet ? ma_chi_tiet : '');

    bodyFormData.append('danhMucVatTu[tk_ketoan][tentk]', ten_tai_khoan);

    return apiServiceInstance().vueInstance.axios.post(
      '/api/v1/danh-muc-vat-tu/tao-moi',
      bodyFormData
    );
  }

  static update({
    ten_danh_muc,
    ma_danh_muc,
    mo_ta,
    id_cha,
    id_tai_khoan,
    ma_chi_tiet,
    ma_tai_khoan,
    ten_tai_khoan,
    id,
  }: IUpdateDanhMucVatTuPayload): Promise<{
    data: ApiResponse<IDanhMucVatTuResponseBase>
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
      ] = "multipart/form-data";
    const bodyFormData = new FormData();

    bodyFormData.append('danhMucVatTu[ten_danh_muc]', ten_danh_muc);
    bodyFormData.append('danhMucVatTu[ma_danh_muc]', ma_danh_muc);
    bodyFormData.append('danhMucVatTu[mo_ta]', mo_ta);
    bodyFormData.append('danhMucVatTu[id_cha]', id_cha.toString());

    bodyFormData.append('danhMucVatTu[tk_ketoan][id]', id_tai_khoan.toString());
    bodyFormData.append('danhMucVatTu[tk_ketoan][matk]', ma_tai_khoan);
    bodyFormData.append('danhMucVatTu[tk_ketoan][mact]', ma_chi_tiet ? ma_chi_tiet : '');
    bodyFormData.append('danhMucVatTu[tk_ketoan][tentk]', ten_tai_khoan);

    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/danh-muc-vat-tu/cap-nhat/${id}`,
      bodyFormData
    );
  }

  static themVatTuItems(
    id: number,
    vatTuIds: number[],
  ) {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
      ] = "multipart/form-data";
    const bodyFormData = new FormData();

    vatTuIds.forEach(id => {
      bodyFormData.append('vatTuInstance[]', id.toString());
    });

    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/danh-muc-vat-tu/assign/${id}`,
      bodyFormData,
    );
  }

  static searchByMaDanhMuc(term: string): Promise<{
    data: ApiResponse<PaginationResponseBase<IDanhMucVatTuResponseBase>>
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/danh-muc-vat-tu/tim-kiem/ma-danh-muc`, {
        params: {
          s: term,
        }
      }
    );
  }

  static delete(id: number) {
    return apiServiceInstance().vueInstance.axios.post(`/api/v1/danh-muc-vat-tu/xoa/${id}`);
  }
}
