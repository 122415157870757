
import { differenceWith } from 'lodash';
import { defineComponent, nextTick, PropType, ref } from 'vue';
import { useAccount } from '@/core/hooks/use-account';
import { ElInput } from 'element-plus';
import { usePagination } from '@/core/hooks/use-pagination';
import { useLoading } from '@/core/hooks/use-loading';
import { VatTuService } from '@/core/services/VatTu.service';
import { AddVatTuItemsFor } from '@/core/enums/addVatTuItemsFor.enum';
import { IVatTuResponseBase, IVatTuSearchInKhoResponseBase } from '@/core/interfaces/ApiResponses';
import { getErrorMsg, instanceOfIVatTuSearchInKhoResponseBase, swalNotification } from '@/core/helpers/utils';
import { KhoService } from '@/core/services/Kho.service';
import { useButtonCustom } from '@/core/hooks/use-button-custom';

import DivRow from '../../layout/_shared/DivRow.vue';
import DivCol from '../../layout/_shared/DivCol.vue';
import DanhMucVatTuService from '../../core/services/DanhMucVatTu.service';

import ButtonCustom from '../shared/buttons/ButtonCustom.vue';
import AddVatTuTableItems from './add-vat-tu-table-items/AddVatTuTableItems.vue';

export default defineComponent({
  name: 'add-vat-tu-items-v2',

  components: {
		AddVatTuTableItems,
		ButtonCustom,
		DivCol,
		DivRow,
	},

	emits: ['close', 'added'],

  setup() {
    const { currentUserData } = useAccount();

    const {
      currentPage,
      totalItems,
      perPage,
      perPageArr,
    } = usePagination([5, 10, 20, 50]);

    const { isLoading } = useLoading();

		const { ButtonTypeColors, ButtonsType } = useButtonCustom();

		const vatTuInput = ref<null | typeof ElInput>(null);

		const danhMucVatTuInput = ref<null | typeof ElInput>(null);

		const soLuongInput = ref<null | typeof ElInput>(null);

    return {
			vatTuInput,
			danhMucVatTuInput,
			soLuongInput,
			currentUserData,
      currentPage, totalItems, perPage, perPageArr,
      isLoading,
			ButtonTypeColors, ButtonsType,
		}
  },

  props: {
		isModalOpen: {
			type: Boolean,
			required: true,
		},
    khoId: {
			type: Number,
			default: 0,
		},
    useFor: {
			type: String as PropType<AddVatTuItemsFor>,
			default: AddVatTuItemsFor.KHO,
		},
		vatTuSelected: {
			type: Array as PropType<Array<IVatTuResponseBase>>,
			default: () => ([]),
		}
  },

  data() {
    return {
      isOpen: false,
      totalVatTuItems: 0,
      displayingVatTuItems: [] as IVatTuResponseBase[],
			selectingVatTuItems: [] as IVatTuResponseBase[],
			isSelected: false,
      danhMucVatTuId: 0,
			vatTuId: 0,
			tenDanhMucVatTu: '',
			tenVatTu: '',
      isDanhMucItemsAvailable: true,
			isVatTuItemsAvailable: true,
			so_luong: 1,
      soLuongLimit: 0,
			don_gia: 1000,
    }
  },

  watch: {
    async isModalOpen(value) {
			this.isOpen = value;

			await nextTick(() => {
				if (this.danhMucVatTuInput) {
					this.danhMucVatTuInput.focus();
				}
			});

			if (this.danhMucVatTuInput) {
				this.danhMucVatTuInput.focus();
			}
		},

    tenVatTu(value) {
			if (!value) {
				this.isVatTuItemsAvailable = true;
			}
		},
  },

  computed: {
    AddVatTuItemsFor() {
      return AddVatTuItemsFor
    },
    isDonGiaInputDisabled() {
      return this.vatTuId === 0 || this.so_luong === 0;
    },
    titleDisplay() {
      if (this.useFor === AddVatTuItemsFor.ADD_VAT_TU_DINH_MUC_CO_DINH) {
        return 'Thêm vật tư vào định mức cố định';
      }

			if (this.useFor === AddVatTuItemsFor.DINH_MUC) {
				return 'Thêm vật tư vào định mức';
			}

      return 'Thêm vật tư';
    },
    isAddButtonDisable() {
			return this.vatTuId === 0 || this.so_luong <= 0 || isNaN(this.so_luong);
		},
		emptyText() {
			return 'Chưa có vật tư nào được chọn';
		}
  },

  methods: {
		onRemoveItem(id: number) {
			this.selectingVatTuItems = this.selectingVatTuItems.filter(item => item.id !== id);
		},

    onClose() {
			this.isOpen = false;

			this.$emit('close');
		},

    vatTuItemsTransform(items: Array<IVatTuResponseBase>): Array<IVatTuResponseBase> {
      if (this.useFor === AddVatTuItemsFor.KHO) {
        return items.map(vatTu => ({
          ...vatTu,
          don_gia: vatTu[`don_gia_kho_${this.khoId}`],
					so_luong: vatTu[`so_luong_kho_${this.khoId}`],
        }));
      }

      return [];
    },

    async queryDanhMucVatTu(queryString: string, fn) {
			const {
        data: { data: { data } } } = await DanhMucVatTuService.searchByMaDanhMuc(queryString);

			if (data.length === 0 && queryString) {
				this.isDanhMucItemsAvailable = false;
			} else if (data.length >= 0) {
				this.isDanhMucItemsAvailable = true;
			}

			fn(data);
		},

    async queryVatTu(queryString: string, fn) {
      let { data: { data: result } } = await VatTuService.getSearch({
        term: queryString,
        id_dm_vat_tu: this.danhMucVatTuId,
				...(this.useFor === AddVatTuItemsFor.KHO && {
					id_kho_exclude: this.khoId,
				}),
        ...(this.useFor === AddVatTuItemsFor.PHIEU_NHAP_KHO_KHAC && {
          id_kho: this.khoId,
        }),
      });

			result = differenceWith(result, this.vatTuSelected,
          (newItem, selectedItem: IVatTuResponseBase) => {
				return newItem.id === selectedItem.id;
			});

      fn(result);
    },

    onSelectDanhMucVatTu({ id }) {
			this.danhMucVatTuId = id;

			if (this.vatTuInput) {
				this.vatTuInput.focus();
			}
		},

    async onSelectVatTu(vatTu: IVatTuSearchInKhoResponseBase | IVatTuResponseBase) {
      const { id, don_gia, so_luong } = vatTu;

			this.isSelected = this.selectingVatTuItems.findIndex(item => item.id === id) >= 0;

			if (this.isSelected) {
				return;
			}

			this.vatTuId = id;

      if (
          this.useFor === AddVatTuItemsFor.PHIEU_NHAP_KHO_KHAC
          && instanceOfIVatTuSearchInKhoResponseBase(vatTu)
      ) {
        this.don_gia = +vatTu.kho[0].pivot.don_gia;

        this.soLuongLimit = +vatTu.kho[0].pivot.so_luong;
      }

			if (this.soLuongInput) {
				this.soLuongInput.focus();
			}
		},

    async onAddVatTu() {
			this.isLoading = true;

      const {
        data: {
          data: res,
        },
      } = await VatTuService.fetchSupplyDetail(this.vatTuId);

      await nextTick();

			if (res) {
				this.isLoading = false;

				this.selectingVatTuItems.push({
					...res,
					so_luong: this.so_luong,
          ...(this.useFor === AddVatTuItemsFor.PHIEU_NHAP_KHO_KHAC && {
            don_gia: +this.don_gia,
            pivot: {
              don_gia: +this.don_gia
            },
          }),
				});

				if (this.danhMucVatTuInput) {
					this.danhMucVatTuInput.focus();
				}

				this.tenVatTu = '';

				this.vatTuId = 0;

				this.so_luong = 1;

				this.don_gia = 1000;

				this.danhMucVatTuId = 0;

        this.soLuongLimit = Number.POSITIVE_INFINITY;
			}

			this.isLoading = false;
    },

		async submit() {
			this.danhMucVatTuId = 0;

			this.vatTuId = 0;

			this.tenDanhMucVatTu = '';

			this.so_luong = 1;

			this.don_gia = 1000;

			if ([
        AddVatTuItemsFor.DINH_MUC,
        AddVatTuItemsFor.PHIEU_NHAP_KHO_KHAC,
        AddVatTuItemsFor.ADD_VAT_TU_DINH_MUC_CO_DINH,
      ].includes(this.useFor)) {
				this.$emit('added', {
					selectingVatTuItems: this.selectingVatTuItems,
				});

				this.displayingVatTuItems = [];

				this.selectingVatTuItems = [];

				return;
			}

			try {
				await KhoService.assignVatTuItemVaoKho({
					id_kho: this.khoId,
					vat_tu_items: this.selectingVatTuItems.map(({
						id,
						so_luong,
					}) => ({
						id, so_luong,
					}))
				});

				this.$emit('added');
			} catch (e) {
				this.isOpen = false;

				await swalNotification(
					getErrorMsg(e, 'có lỗi khi gán vật tư vào kho này'),
					'error',
				);

				this.$emit('added');
			}
		}
  }
})
