import { ref, watch } from 'vue';
import { DEFAULT_PER_PAGE_KEY } from '@/core/constants/const';

const PER_PAGE_DEFAULT = window.localStorage.getItem(DEFAULT_PER_PAGE_KEY) ? window.localStorage.getItem(DEFAULT_PER_PAGE_KEY) : 10;

export const usePagination = (
  perPageArr = [10, 20, 50, 100],
  pageSize = PER_PAGE_DEFAULT,
) => {
  const currentPage = ref(1);

  const totalItems = ref(0);

  const perPage = ref(pageSize ? +pageSize : 10);

  const loading = ref(false);

  const searchTerm = ref('');

  const indexDisplay = (index) => {
    return (currentPage.value - 1) * perPage.value + index + 1
  }

  watch(perPage, (value) => {
    window.localStorage.setItem(DEFAULT_PER_PAGE_KEY, value.toString());

    currentPage.value = 1;
  });

  watch(searchTerm, () => {
    currentPage.value = 1;
  });

  const indexCal = (index: number, page = 0, perPage = 0) => {
    if (!page) return index + 1;

    return (page - 1) * perPage + index + 1
  }

  return {
    currentPage,
    totalItems,
    searchTerm,
    perPage,
    indexCal,
    indexDisplay,
    perPageArr,
    loading,
  }
}
